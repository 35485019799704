/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */

import Grid from "@mui/material/Grid";

function Footer() {
  return (
    <>
      <div
        className="container text-light footer fadeIn title "
        style={{
          backgroundColor: "#13226c",
        }}
      >
        <div className="copyright">
          <Grid container>
            <Grid item xs={12} sm={6} md={9.5} style={{ marginLeft: "2.5em" }}>
              &copy;
              <a className="border-bottom">CONAS:Connecting Assets</a>, All
              Right Reserved. Designed By &nbsp;
              <a className="border-bottom ">CONAS 2025</a>
              <br></br>
              <br></br>
              <a
                href="https://www.linkedin.com/company/conas-consulting-pvt-ltd/"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="40"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 256 256"
                >
                  <rect
                    x="0"
                    y="0"
                    width="256"
                    height="256"
                    fill="none"
                    stroke="none"
                  />
                  <path
                    fill="#0A66C2"
                    d="M218.123 218.127h-37.931v-59.403c0-14.165-.253-32.4-19.728-32.4c-19.756 0-22.779 15.434-22.779 31.369v60.43h-37.93V95.967h36.413v16.694h.51a39.907 39.907 0 0 1 35.928-19.733c38.445 0 45.533 25.288 45.533 58.186l-.016 67.013ZM56.955 79.27c-12.157.002-22.014-9.852-22.016-22.009c-.002-12.157 9.851-22.014 22.008-22.016c12.157-.003 22.014 9.851 22.016 22.008A22.013 22.013 0 0 1 56.955 79.27m18.966 138.858H37.95V95.967h37.97v122.16ZM237.033.018H18.89C8.58-.098.125 8.161-.001 18.471v219.053c.122 10.315 8.576 18.582 18.89 18.474h218.144c10.336.128 18.823-8.139 18.966-18.474V18.454c-.147-10.33-8.635-18.588-18.966-18.453"
                  />
                </svg>
              </a>
            </Grid>

            <Grid item xs={12} sm={6} md={2} style={{ paddingBottom: "2em" }}>
              <div className="footer-menu">
                <a href="/">Home</a>
                <a href="/Contact">Contact</a>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Footer;
