import React, { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@mui/material";

const CAccordion = ({ CHeader = "No Header Available", CData = [] ,CRows = [],COpen=true }) => {
  const [isOpen, setIsOpen] = useState(COpen);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <div
    style={{
      border: "1px solid #3CB043", // Green border
      borderRadius: "12px",
      margin: "10px 0",
      overflow: "hidden",
    }}
  >
    {/* Header */}
    <div
      onClick={toggleAccordion}
      style={{
        backgroundColor: "#3CB043", 
        color: "#FFFFFF",
        padding: "7px 15px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        fontSize: "1.2em", 
      }}
    >
      <div
        style={{
          flex: 1,
          textAlign: "center"
        }}
      >
        {CHeader}
      </div>
      <div>
        {isOpen ? (
          <ExpandLessIcon style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMoreIcon style={{ color: "#FFFFFF" }} />
        )}
      </div>
    </div>

    {/* Content - Only visible when open */}
    {isOpen && (
      <div style={{ padding: "10px", backgroundColor: "#FFFFFF" }}>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {CData?.map((actualArr, rowIndex) => (
                <TableRow key={rowIndex}>
                  {CRows.map((rowData, cellIndex) => (
                    <TableCell
                      component="th"
                      scope="row"
                      key={cellIndex}
                      style={{
                        padding: "0em 0.2em 0em 2em",
                        margin: "0em",
                        border: "none",
                        fontWeight: "bold",
                        width: cellIndex === 0 ? "40%" : "60%",
                      }}
                    >
                      {actualArr?.map((actualRow, rowIndex) => (
                        <TableRow key={rowIndex}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              textAlign: "left",
                              padding: "0.8em 0em 0.8em 0em",
                              verticalAlign: "middle",
                              fontSize: "1.05em",
                              border: "none",
                            }}
                          >
                            {rowData.type === "T" &&
                              actualRow[rowData.value]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )}
  </div>
  );
};

export default CAccordion;
