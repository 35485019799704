import React from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";

const CAuthPopUpBox = ({CText}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/services");
  };
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2000,
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          width: "80%",
          maxWidth: "400px",
          textAlign: "center",
        }}
      >
        <button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "20px",
            right: "10px",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <CloseIcon style={{ fontSize: "35px" }} />
        </button>

        <WarningIcon
          style={{
            fontSize: "70px",
            color: "red",
            margin: "60px 30px 0",
          }}
        />
        <p
          style={{
            fontSize: "22px",
            color: "#333",
            lineHeight: "35px",
            fontWeight: "300",
            padding: "0 10px",
          }}
        >
          {CText}
        </p>

        {/* Close Button */}
        <button
          onClick={handleClose}
          style={{
            background: "none",
            border: "none",
            width: "100%",
            fontSize: "20px",
            cursor: "pointer",
            height: "50px",
            backgroundColor: "red",
            color: "#fff",
            borderRadius: "0 0 10px 10px",
            marginTop: "20px", 
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CAuthPopUpBox;
