import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Card from "@mui/material/Card";
import { Box, styled } from "@mui/system";
import WarehouseLocationVm from "../viewmodel/WarehouseLocationVm";
import CBackDrop from "../../../basecomp/components/CBackDrop";
import CAccordion from "../../../basecomp/components/CAccordion";
import CAuthPopUpBox from "../../../basecomp/components/CAuthPopUpBox";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import materialCountedPostApiCall from "../model/MaterialCountModel";
import CountingConfirmation from "./CountingConfirmation";
import { useToast } from "../../../basecomp/components/provider/ToastProvider";
const commonFontSize = ".9em";
const OrderBoxText = styled("div")({
  textAlign: "center",
  backgroundColor: "#3CB043",
  color: "white",
  padding: ".5em 3em",
  borderRadius: "0.7em 0.7em 0em 0em",
  fontSize: "1.2em",
  marginTop: "1em",
});
const StyledInput = styled("input")(({ theme }) => ({
  width: "60px",
  height: "30px",
  padding: "0",
  borderRadius: "4px",
  backgroundColor: "red",
  fontSize: ".9em",
  outline: "none",
  border: "none",
  color: "white",
  textAlign: "center",
  transition: "background-color 0.2s ease",
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "&[type=number]": {
    "-moz-appearance": "textfield", // Firefox
  },
  "&:focus": {
    backgroundColor: "white",
    border: "1px solid red",
    color: "black",
  },
}));
const WareHouseCounting = () => {
  const {
    wareHouseMaterialData,
    terminal_id,
    storage_loc,
    loading,
    storage_bin,
    popUpBox,
  } = WarehouseLocationVm();
  const { handleToastOpen } = useToast();

  const [assetData, setAssetData] = useState([]);
  const [infoRowData, setInfoRowData] = useState([]);
  const [inventoryStatus, setInventoryStatus] = useState({});
  const [okResults, setOkResults] = useState([]);
  const [nokResults, setNokResults] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [firstClickTimestamp, setFirstClickTimestamp] = useState(null);

  const formatTimestamp = () => {
    try {
      const timeZone= localStorage.getItem("time_zone");
      if (!timeZone || typeof timeZone !== 'string' || timeZone.trim() === '') {
        console.warn("Invalid or missing timezone in localStorage");
        return null; 
      }
      const date = new Date();
      return new Intl.DateTimeFormat("en-US", {
        timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      })
        .format(date)
        .replace(",", "")
        .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2");
    } catch (error) {
      console.error("Error formatting timestamp:", error);
      return null;
    }
  };

  useEffect(() => {
    setFirstClickTimestamp(formatTimestamp());
  }, []);

  const getFormattedDateTime = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    const currentTime = currentDate.toLocaleTimeString();
    return formattedDate + "_" + currentTime;
  };

  const handleCount = async () => {
    setSendLoading(true);
    try {
      if (nokResults && nokResults.length > 0) {
        for (const result of nokResults) {
          const qty = result.updated_qty;

          if (!qty || qty.length === 0 || qty === "") {
            handleToastOpen(
              "error",
              "Invalid counted quantity! Please enter a valid number."
            );
            setSendLoading(false);
            return;
          }

          if (qty > 500) {
            handleToastOpen(
              "error",
              "Invalid counted quantity range! Maximum allowed is 500."
            );
            setSendLoading(false);
            return;
          }
        }
      }

      const payload = {
        storage_bin,
        storage_loc,
        first_action_timestamp: firstClickTimestamp,
        okresults: okResults.map((result) => ({
          material_no: result.material_no,
          bin_location: result.bin_location,
          ohqty: result.ohqty,
          material_desc: result.material_desc,
          updated_qty: null,
          isUpdated: false,
        })),
        nokresults: nokResults.map((result) => ({
          material_no: result.material_no,
          bin_location: result.bin_location,
          ohqty: result.ohqty,
          material_desc: result.material_desc,
          updated_qty: Number(result.updated_qty),
          isUpdated: true,
        })),
      };

      const response = await materialCountedPostApiCall(
        "/gsone/materialCounting",
        payload
      );

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Material_Counted_List_${getFormattedDateTime()}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      if (response.data) {
        setConfirmationBox(true);
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    } finally {
      setSendLoading(false);
    }
  };

  const handleOkClick = (material_no, ohqty, bin_location, material_desc) => {
    // if (!firstClickTimestamp) {
    //   setFirstClickTimestamp(formatTimestamp());
    // }

    setInventoryStatus((prev) => ({
      ...prev,
      [material_no]: {
        ...prev[material_no],
        okClicked: true,
      },
    }));

    setOkResults((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.material_no === material_no
      );

      const resultItem = {
        material_no,
        ohqty,
        bin_location,
        material_desc,
      };

      if (existingIndex !== -1) {
        const updatedResults = [...prev];
        updatedResults[existingIndex] = resultItem;
        return updatedResults;
      } else {
        return [...prev, resultItem];
      }
    });
  };

  const handleNokClick = (material_no, ohqty, bin_location, material_desc) => {
    // if (!firstClickTimestamp) {
    //   setFirstClickTimestamp(formatTimestamp());
    // }

    setInventoryStatus((prev) => ({
      ...prev,
      [material_no]: {
        ...prev[material_no],
        nokClicked: true,
        okClicked: true,
        validationPassed: false,
        originalOhqty: ohqty, // Store the original value when NOK is clicked
      },
    }));

    // Initialize nokResults with original value
    setNokResults((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.material_no === material_no
      );

      const resultItem = {
        material_no,
        ohqty: ohqty, // Store original quantity
        bin_location,
        material_desc,
        updated_qty: ohqty, // Initially same as original
        isUpdated: true,
      };

      if (existingIndex !== -1) {
        const updatedResults = [...prev];
        updatedResults[existingIndex] = resultItem;
        return updatedResults;
      } else {
        return [...prev, resultItem];
      }
    });
  };

  const handleNokValueChange = (
    material_no,
    newValue,
    currentOhqty,
    bin_location,
    material_desc
  ) => {
    const parsedNewValue = newValue;
    const parsedCurrentOhqty = parseInt(currentOhqty);
    const isValid = parsedNewValue !== parsedCurrentOhqty;

    setInventoryStatus((prev) => ({
      ...prev,
      [material_no]: {
        ...prev[material_no],
        ohqty: parsedCurrentOhqty,
        updatedOhq: parsedNewValue,
        validationPassed: isValid,
      },
    }));

    if (isValid) {
      setNokResults((prev) => {
        const existingIndex = prev.findIndex(
          (item) => item.material_no === material_no
        );

        const resultItem = {
          material_no,
          ohqty: parsedCurrentOhqty, // Keep the original quantity
          bin_location,
          material_desc,
          updated_qty: parsedNewValue, // Set the new input value
          isUpdated: true,
        };

        if (existingIndex !== -1) {
          const updatedResults = [...prev];
          updatedResults[existingIndex] = resultItem;
          return updatedResults;
        } else {
          return [...prev, resultItem];
        }
      });
    }
  };

  const handleCloseClick = (material_no) => {
    setInventoryStatus((prev) => ({
      ...prev,
      [material_no]: {
        okClicked: false,
        nokClicked: false,
        validationPassed: false,
        updatedOhq: null,
      },
    }));

    setOkResults((prev) =>
      prev.filter((item) => item.material_no !== material_no)
    );
    setNokResults((prev) =>
      prev.filter((item) => item.material_no !== material_no)
    );
  };

  useEffect(() => {
    if (
      wareHouseMaterialData?.length ===
      okResults?.length + nokResults?.length
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [wareHouseMaterialData?.length, okResults?.length, nokResults?.length]);

  useEffect(() => {
    setAssetData([
      [{ name: "Plant ID", value: terminal_id }],
      [{ name: "Storage Location", value: storage_loc }],
      [{ name: "Storage Bin Group", value: storage_bin }],
    ]);
    setInfoRowData([
      { value: `name`, type: "T" },
      { value: `value`, type: "T" },
    ]);
  }, [terminal_id, storage_loc, storage_bin]);

  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={10}
      lg={10}
      xl={10}
      sx={{ margin: "5em 0.5em 0.5em 0.5em" }}
    >
      {popUpBox ? (
        <CAuthPopUpBox CText="You are not authorized to view the details of this asset" />
      ) : null}
      {confirmationBox ? (
        <CountingConfirmation
          CText="Email has been sent successfully!"
          TotalOK={okResults.length}
          TotalNOK={nokResults.length}
          terminal_id={terminal_id}
          storage_bin={storage_bin}
          storage_loc={storage_loc}
        />
      ) : null}
      <CBackDrop cOpen={loading || sendLoading} />

      <Card sx={{ padding: "0.5em" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            marginBottom: "1em",
          }}
        >
          <Tooltip title="Click here to send the counting details">
            <IconButton
              id="mail"
              style={{
                backgroundColor: isDisabled ? "#B0B0B0" : "#052660",
                color: isDisabled ? "black" : "#FFFFFF",
                borderRadius: 5,
                padding: "8px",
                cursor: isDisabled ? "not-allowed" : "pointer",
                opacity: isDisabled ? 0.6 : 1,
              }}
              onClick={handleCount}
              disabled={isDisabled}
            >
              <MailIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
        <CAccordion
          CHeader="General Info"
          CData={assetData}
          CRows={infoRowData}
          COpen={false}
        />

        <OrderBoxText>Inventory Location Details</OrderBoxText>
        <Box
          sx={{
            border: "1px solid #3CB043",
            borderRadius: "0 0 1em 1em",
            overflowX: "auto",
            whiteSpace: "nowrap",
            marginBottom: "3em",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Table sx={{ minWidth: "1000px", tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "6px 8px",
                    fontSize: commonFontSize,
                    width: "120px",
                  }}
                >
                  Bin Location
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "6px 12px",
                    fontSize: commonFontSize,
                    width: "140px",
                  }}
                >
                  Material Number
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "6px 12px",
                    fontSize: commonFontSize,
                    width: "300px",
                    textAlign: "center",
                    margin: "0 2em",
                  }}
                >
                  OHQ
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    padding: "6px 12px",
                    fontSize: commonFontSize,
                  }}
                >
                  Material Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wareHouseMaterialData?.map((row, rowIndex) => {
                const status = inventoryStatus[row.material_no] || {};
                return (
                  <TableRow key={rowIndex}>
                    <TableCell
                      sx={{
                        padding: "8px",
                        fontSize: commonFontSize,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.bin_location}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px 12px",
                        fontSize: commonFontSize,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.material_no}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px 12px",
                        fontSize: commonFontSize,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        position: "relative",
                        minWidth: "300px",
                        height: "40px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          position: "absolute",
                          left: 0,
                          right: 0,
                        }}
                      >
                        <span
                          style={{
                            margin: "0px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "40px",
                            textAlign: "center",
                          }}
                        >
                          {row.ohqty}
                        </span>

                        {!status.okClicked && !status.nokClicked && (
                          <>
                            <button
                              onClick={() =>
                                handleOkClick(
                                  row.material_no,
                                  row.ohqty,
                                  row.bin_location,
                                  row.material_desc
                                )
                              }
                              style={{
                                backgroundColor: "#3CB043",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                width: "60px",
                                position: "absolute",
                                left: "50px",
                              }}
                            >
                              OK
                            </button>
                            <button
                              onClick={() =>
                                handleNokClick(
                                  row.material_no,
                                  row.ohqty,
                                  row.bin_location,
                                  row.material_desc
                                )
                              }
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                padding: "5px 10px",
                                borderRadius: "4px",
                                cursor: "pointer",
                                width: "60px",
                                position: "absolute",
                                right: "50px",
                              }}
                            >
                              NOK
                            </button>
                          </>
                        )}

                        {status.nokClicked && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              position: "absolute",
                              right: "50px",
                              gap: "10px",
                            }}
                          >
                            <StyledInput
                              type="number"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              min="0"
                              step="1"
                              defaultValue={row.ohqty}
                              onKeyDown={(e) => {
                                const allowedKeys = [
                                  "Backspace",
                                  "Delete",
                                  "Tab",
                                  "Escape",
                                  "Enter",
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "Home",
                                  "End",
                                ];

                                if (
                                  !(
                                    (e.key >= "0" && e.key <= "9") ||
                                    allowedKeys.includes(e.key)
                                  )
                                ) {
                                  e.preventDefault();
                                }

                                if (e.ctrlKey || e.metaKey) {
                                  if (e.key === "v" || e.key === "V") {
                                    navigator.clipboard
                                      .readText()
                                      .then((text) => {
                                        if (!/^\d+$/.test(text)) {
                                          e.preventDefault();
                                        }
                                      });
                                  }
                                }
                              }}
                              onChange={(e) => {
                                let sanitizedValue = e.target.value.replace(
                                  /[^\d]/g,
                                  ""
                                );

                                // If input is empty, set it to blank instead of "0"
                                if (sanitizedValue === "") {
                                  e.target.value = "";
                                } else {
                                  e.target.value =
                                    sanitizedValue.replace(/^0+/, "") || "0";
                                }

                                handleNokValueChange(
                                  row.material_no,
                                  e.target.value,
                                  row.ohqty,
                                  row.bin_location,
                                  row.material_desc
                                );
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                const pastedText =
                                  e.clipboardData.getData("text");

                                if (/^\d+$/.test(pastedText)) {
                                  const sanitizedValue =
                                    pastedText.replace(/^0+/, "") || "";
                                  e.target.value = sanitizedValue;

                                  handleNokValueChange(
                                    row.material_no,
                                    sanitizedValue,
                                    row.ohqty,
                                    row.bin_location,
                                    row.material_desc
                                  );
                                }
                              }}
                            />

                            {!status.validationPassed && (
                              <CloseIcon
                                sx={{
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleCloseClick(row.material_no)
                                }
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "8px 12px",
                        fontSize: commonFontSize,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.material_desc}
                    </TableCell>
                  </TableRow>
                );
              })}
              {wareHouseMaterialData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    No data present
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </Card>
    </Grid>
  );
};

export default WareHouseCounting;
