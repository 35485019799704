import axios from "axios";
import UserUtils from "../../../basecomp/auth/UserUtils";
const Base_URL = process.env.REACT_APP_BE_URL;

const apiKey = "x-api-key";
const { handleLoginError, getTokenFromEmail } = UserUtils;

const responseHandling = (response) => {

    if (response === undefined) {
      throw new Error("No response");
    }
    if (response.status >= 200 && response.status <= 204) {
      if (response.data !== undefined && response.data.status !== undefined && response.data.status === 204) {
        throw new Error(response.data.message);
      } else {
        return response;
      }
    }
  }

const materialCountedPostApiCall = async (url, formData) => {
    let response;
    try {
      const token = getTokenFromEmail();
      response = await axios.post(`${Base_URL}${url}`, formData, {
        headers: {
            [apiKey]: token
        },
        responseType: 'blob', 
      });

    } catch (error) {
      
      handleLoginError(error);
    }
    return responseHandling(response);
  };

export default materialCountedPostApiCall;